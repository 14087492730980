import React from "react";
import { RefundHistoryVerificationStatesWrapper } from "../../RefundHistoryVerificationStatesWrapper";
import { MailLink } from "features/Common/modules/MailLink/MailLink";
import { useRequestVerificationModal } from "features/Account/modules/SubSectionWrapper/modules/RequestVerificationStripe/modules/utils/useRequestVerificationModal";
import { PAYMENT_OPTION_KEYS } from "features/Account/modules/PaymentOptions/constants/PaymentOptions.constants";
import { RequestVerificationModal } from "features/Account/modules/SubSectionWrapper/modules/RequestVerificationStripe/modules/RequestVerificationModal/RequestVerificationModal";
import ErrorIcon from "@material-ui/icons/Error";
import styles from "./RefundHistoryVerificationFailedState.module.css";

export const RefundHistoryVerificationFailedState = () => {
  const { openRequestVerificationModal } = useRequestVerificationModal({
    key: PAYMENT_OPTION_KEYS.CUSTOMER_REFUND,
  });
  return (
    <>
      <RefundHistoryVerificationStatesWrapper
        title="Initiate Customer Refunds Effortlessly"
        description={
          <>
            Verification has failed. Please try again or contact <MailLink />{" "}
            for any queries.
          </>
        }
        btnProps={{ onClick: openRequestVerificationModal }}
        Icon={<ErrorIcon className={styles.error} />}
      />
      <RequestVerificationModal />
    </>
  );
};
