import React from "react";
import { RefundHistoryVerificationStatesWrapper } from "../../RefundHistoryVerificationStatesWrapper";
import { RequestVerificationModal } from "features/Account/modules/SubSectionWrapper/modules/RequestVerificationStripe/modules/RequestVerificationModal/RequestVerificationModal";
import { useRequestVerificationModal } from "features/Account/modules/SubSectionWrapper/modules/RequestVerificationStripe/modules/utils/useRequestVerificationModal";
import { PAYMENT_OPTION_KEYS } from "features/Account/modules/PaymentOptions/constants/PaymentOptions.constants";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import ExlyImage from "common/Components/ExlyImage";

export const RefundHistoryAccessLockedState = () => {
  const { openRequestVerificationModal } = useRequestVerificationModal({
    key: PAYMENT_OPTION_KEYS.CUSTOMER_REFUND,
  });
  return (
    <>
      <RefundHistoryVerificationStatesWrapper
        title="Initiate Customer Refunds Effortlessly"
        description="You’re only 1 step away! Initiate verification request below and start using reports."
        btnProps={{ onClick: openRequestVerificationModal }}
        Icon={
          <ExlyImage
            src={getS3AssetCDNUrl(
              "assets/images/transactions/refunds/new_releases.png"
            )}
            alt="loading"
            height={32}
            width={32}
            className="mb-2"
          />
        }
      />
      <RequestVerificationModal />
    </>
  );
};
