import React from "react";
import style from "./RefundHistoryVerificationStatesWrapperStyles.module.css";

export const RefundHistoryVerificationStatesWrapper = ({
  title = "",
  description = "",
  Icon = <></>,
  btnProps = {},
}) => {
  const { text, disabled, onClick, btnIcon = <></> } = btnProps;
  return (
    <div className={style.featureTextContainer}>
      <div className={style.innerBox}>
        {Icon}
        <div className={style.heading}>{title}</div>
        <div className={`${style.description} mt-1`}>{description}</div>
        <div
          className={`${style.upgradeBtn} mt-3 ${disabled && style.disabled}`}
          onClick={onClick}
        >
          {btnIcon}
          {text || "Request Verification"}
        </div>
      </div>
    </div>
  );
};
