import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import React from "react";
import { Form } from "react-final-form";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import {
  AUTO_DEBIT_CANCELLATION_APIS,
  AUTO_DEBIT_CANCELLATION_FORM_KEYS,
} from "./constants/AutoDebitCancellationModal.constants";
import { CancellationForm } from "./modules/CancellationForm/CancellationForm";
import { SuccessScreen } from "./modules/SuccessScreen/SuccessScreen";

export const AutoDebitCancellationModal = ({ record, open, onClose }) => {
  const { notify } = useNotifications();
  const { isSuccess: showSuccessScreen, mutate } = useCustomMutation({
    url: AUTO_DEBIT_CANCELLATION_APIS.revoke_subscription,
    onError: handleError,
  });

  function handleError(error) {
    notify(
      error?.response?.data?.message || SOMETHING_WENT_WRONG,
      notification_color_keys.error
    );
    logError({
      error,
      occuredAt:
        "src/features/Transactions/modules/AutoDebitCancellationModal/AutoDebitCancellationModal.jsx",
    });
  }

  const handleCancel = (formValues) => {
    const { [AUTO_DEBIT_CANCELLATION_FORM_KEYS.order_id]: orderId } = record;

    const handleSuccess = () => {
      notify(
        "Auto-Debit cancellation request submitted",
        notification_color_keys.success
      );
      onClose();
    };

    return new Promise((onSuccess, onError) => {
      mutate(
        {
          ...formValues,
          [AUTO_DEBIT_CANCELLATION_FORM_KEYS.order_id]: orderId,
        },
        { onSuccess, onError }
      );
    })
      .then(handleSuccess)
      .catch(handleError);
  };

  return (
    <Form onSubmit={handleCancel}>
      {({ submitting, pristine, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ExlyModal
            title={
              showSuccessScreen
                ? "Review Submitted"
                : "Are you sure you want to cancel Auto-Debit?"
            }
            open={open}
            onClose={onClose}
            className="!tw-p-6 max-md:!tw-p-4"
            primaryBtnText={showSuccessScreen ? "Okay" : "Submit for Review"}
            modal_props={{
              footerClassName: "!tw-justify-between",
            }}
            showSecondaryBtn={!showSuccessScreen}
            primaryBtnProps={{
              fullWidth: showSuccessScreen,
              loading: submitting,
              disabled: submitting || pristine,
            }}
            onPrimaryBtnClick={handleSubmit}
          >
            {showSuccessScreen ? <SuccessScreen /> : <CancellationForm />}
          </ExlyModal>
        </form>
      )}
    </Form>
  );
};
