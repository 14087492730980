import { FEATURE_STATUS } from "features/Account/Account.constants";
import { PAYMENT_OPTION_KEYS } from "features/Account/modules/PaymentOptions/constants/PaymentOptions.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import { getKeyLevelSettings } from "features/AdminPanel/utils/AdminPanel.utils";
import { logError } from "utils/error";

export const useRefundHistoryVerificationStates = () => {
  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const refundFeatureState =
    sectionConfig[PAYMENT_OPTION_KEYS.CUSTOMER_REFUND]?.status;

  const setSectionConfig = useAdminPanelStore(
    (state) => state.setSectionConfig
  );
  const isPlanLocked = refundFeatureState === FEATURE_STATUS.PLAN_LOCKED;

  const fetchRefundHistoryVerificationStates = async () => {
    if (refundFeatureState) return;
    try {
      const response = await getKeyLevelSettings([
        PAYMENT_OPTION_KEYS.CUSTOMER_REFUND,
      ]);
      if (response?.data)
        setSectionConfig({
          ...sectionConfig,
          [PAYMENT_OPTION_KEYS.CUSTOMER_REFUND]:
            response.data[PAYMENT_OPTION_KEYS.CUSTOMER_REFUND],
        });
    } catch (error) {
      logError({ error, occuredAt: "Error fetching initial values:" });
    }
  };
  return {
    isPlanLocked,
    refundFeatureState,
    fetchRefundHistoryVerificationStates,
  };
};
