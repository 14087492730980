import React from "react";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { RESOURCE_KEYS } from "utils/OrgPermissions";
import { getRefundFeatureVerificationStatesJSX } from "./utils/RefundHistoryVerificationStates.utils";
import { useRefundHistoryVerificationStates } from "./utils/useRefundHistoryVerificationStates";
import { FeaturePageV2 } from "features/Common/modules/FeaturePageV2/FeaturePageV2";
import styles from "./RefundHistoryVerificationStates.module.css";

export const RefundHistoryVerificationStates = () => {
  const { isPlanLocked, refundFeatureState } =
    useRefundHistoryVerificationStates();

  return (
    <FeaturePageV2
      hideAppBar={true}
      showBottomNav={true}
      featureName={RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.REFUND_HISTORY}
      desktopImage={getS3AssetCDNUrl(
        "assets/images/transactions/refunds/background.png"
      )}
      mobileImage={getS3AssetCDNUrl(
        "assets/images/transactions/refunds/background_mobile.png"
      )}
      classes={{ featureContainer: styles.featureContainer }}
      {...(!isPlanLocked
        ? {
            featureJSX: getRefundFeatureVerificationStatesJSX({
              status: refundFeatureState,
            }),
          }
        : {})}
    />
  );
};
