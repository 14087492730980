import { create } from "zustand";
import { sales_page_template_tabs } from "../constants/SalesPages.constants";

export const useSalesPageStore = create((set) => ({
  selectedTemplateDetails: {},
  setSelectedTemplateDetails: (value) =>
    set({ selectedTemplateDetails: value }),
  selectedTab: sales_page_template_tabs.popular,
  setSelectedTab: (value) => set({ selectedTab: value }),
}));
