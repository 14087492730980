import CircularProgress from "@material-ui/core/CircularProgress";
import { ExlyDropdown, ExlyInput } from "common/form";
import { required, requiredWithMessage } from "common/validate";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { Field, useForm } from "react-final-form";
import {
  AUTO_DEBIT_CANCELLATION_API_KEYS,
  AUTO_DEBIT_CANCELLATION_FORM_KEYS,
  AUTO_DEBIT_CANCELLATION_NOTE,
} from "../../constants/AutoDebitCancellationModal.constants";
import { useAutoDebitCancellationReasons } from "../../utils/useAutoDebitCancellationReasons";

export const CancellationForm = () => {
  const form = useForm();
  const { data, isLoading, isSuccess, isError, error } =
    useAutoDebitCancellationReasons();
  const selectedReason = useFormFieldValue(
    AUTO_DEBIT_CANCELLATION_FORM_KEYS.subscription_revoke_reason_id
  );

  if (isLoading) {
    return (
      <div className="tw-m-4 tw-text-center">
        <CircularProgress />
      </div>
    );
  }

  if (isSuccess) {
    const { [AUTO_DEBIT_CANCELLATION_API_KEYS.revoke_reasons]: reasons } = data;
    const options = reasons.map((reasonObj) => {
      const [[key, reason]] = Object.entries(reasonObj);
      return {
        value: +key,
        label: reason,
      };
    });
    const showOtherReasonInputField = selectedReason === options.length;

    const handleChange = (reason) => {
      if (reason !== options.length) {
        form.change(
          AUTO_DEBIT_CANCELLATION_FORM_KEYS.subscription_revoke_reason,
          undefined
        );
      }
    };

    return (
      <div className="tw-flex tw-flex-col tw-gap-3">
        <div>
          Your cancellation request will be reviewed by our team based on the
          reason you provide. Please select a reason below to proceed.
        </div>

        <Field
          name={AUTO_DEBIT_CANCELLATION_FORM_KEYS.subscription_revoke_reason_id}
          component={ExlyDropdown}
          placeholder="Select a reason"
          options={options}
          size="small"
          validate={requiredWithMessage(
            "Please select any one option to continue"
          )}
          onChange={handleChange}
          fullWidth
        />

        {showOtherReasonInputField && (
          <Field
            name={AUTO_DEBIT_CANCELLATION_FORM_KEYS.subscription_revoke_reason}
            component={ExlyInput}
            placeholder="Enter your reason for cancellation request"
            validate={required}
            fullWidth
          />
        )}

        <div className="tw-text-[#272522] tw-text-sm tw-opacity-50 tw-leading-[normal]">
          {AUTO_DEBIT_CANCELLATION_NOTE}
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>{error?.message || SOMETHING_WENT_WRONG}</div>;
  }
};
