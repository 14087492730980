export const AUTO_DEBIT_CANCELLATION_NOTE =
  "Your request will be processed within 1–2 business days. Rest assured, your funds are secure, and you'll continue to enjoy all unlocked features during this time. You can track progress on the Transactions page or reach out to support if you have questions";

export const AUTO_DEBIT_CANCELLATION_APIS = {
  reasons: "business/credits/credit/subscription/revoke/reason",
  revoke_subscription: "business/credits/credit/subscription/revoke",
};

export const AUTO_DEBIT_CANCELLATION_API_KEYS = {
  revoke_reasons: "revoke_reasons",
};

export const AUTO_DEBIT_CANCELLATION_FORM_KEYS = {
  order_id: "order_id",
  subscription_revoke_reason: "subscription_revoke_reason",
  subscription_revoke_reason_id: "subscription_revoke_reason_id",
};
