import React from "react";
import { FEATURE_STATUS } from "features/Account/Account.constants";
import { RefundHistoryVerificationFailedState } from "../modules/RefundHistoryVerificationStatesWrapper/modules/RefundHistoryVerificationFailedState/RefundHistoryVerificationFailedState";
import { RefundHistoryVerificationInProgressState } from "../modules/RefundHistoryVerificationStatesWrapper/modules/RefundHistoryVerificationInProgressState/RefundHistoryVerificationInProgressState";
import { RefundHistoryAccessLockedState } from "../modules/RefundHistoryVerificationStatesWrapper/modules/RefundHistoryAccessLockedState/RefundHistoryAccessLockedState";
import { RefundHistoryAcessGrantedState } from "../modules/RefundHistoryVerificationStatesWrapper/modules/RefundHistoryAcessGrantedState/RefundHistoryAcessGrantedState";

export const getRefundFeatureVerificationStatesJSX = ({ status }) => {
  switch (status) {
    case FEATURE_STATUS.ACCESS_LOCKED:
      return <RefundHistoryAccessLockedState />;

    case FEATURE_STATUS.ACCESS_REQUESTED:
      return <RefundHistoryVerificationInProgressState />;

    case FEATURE_STATUS.ACCESS_DENIED:
      return <RefundHistoryVerificationFailedState />;

    case FEATURE_STATUS.ACCESS_GRANTED:
      return <RefundHistoryAcessGrantedState />;

    default:
      return <></>; // Empty JSX if the status is unknown
  }
};
