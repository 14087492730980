import React from "react";

export const SuccessScreen = () => {
  return (
    <div className="tw-text-center tw-text-base tw-text-[#524E47]">
      Thanks for submitting your request! We’ll get back to you in 1–2 business
      days. Track its progress on the Transactions page or reach out to support
      if needed
    </div>
  );
};
