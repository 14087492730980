import React from "react";
import { RefundHistoryVerificationStatesWrapper } from "../../RefundHistoryVerificationStatesWrapper";
import { CheckCircle } from "@material-ui/icons";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";
import { whats_next_keys } from "features/Onboarding/modules/WhatsNext/WhatsNext.constants";
import { useAccountSectionUnlocking } from "features/Account/utils/useAccountSectionChange";
import { useSignUpBonusActiveRefundsModal } from "features/Account/modules/SignUpBonusActiveRefundsModal/utils/useSignUpBonusActiveRefundsModal";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import { SignUpBonusActiveRefundsModal } from "features/Account/modules/SignUpBonusActiveRefundsModal/SignUpBonusActiveRefundsModal";
import { PAYMENT_OPTION_KEYS } from "features/Account/modules/PaymentOptions/constants/PaymentOptions.constants";
import { useAdminPanelStore } from "features/AdminPanel/store/useAdminPanelStore";
import styles from "./RefundHistoryAcessGrantedState.module.css";

export const RefundHistoryAcessGrantedState = () => {
  const whatsNextData = getWhatsNextInfo();
  const key = PAYMENT_OPTION_KEYS.CUSTOMER_REFUND;

  const sectionConfig = useAdminPanelStore((state) => state.sectionConfig);
  const subSectionConfig = sectionConfig[key] || {};
  const { value } = subSectionConfig;
  const { handleSectionChange } = useAccountSectionUnlocking();

  const { openSignUpBonusRefundsModal, isSignUpBonusRefundsModal } =
    useSignUpBonusActiveRefundsModal();

  const handleRefundChange = () => {
    if (whatsNextData[whats_next_keys.has_offer_enabled.key]) {
      openSignUpBonusRefundsModal(true);
    } else {
      const payload = {
        feature_key: key,
        feature_value: !value,
      };
      handleSectionChange({ payload });
    }
  };
  return (
    <>
      <RefundHistoryVerificationStatesWrapper
        title="Verification Successful!"
        description="Easily process refunds to ensure customer satisfaction with smooth transactions."
        Icon={<CheckCircle className={styles.icon} />}
        btnProps={{
          text: "Enable Refunds",
          onClick: handleRefundChange,
        }}
      />
      <LazyLoadWrapper loadingCondition={isSignUpBonusRefundsModal}>
        <SignUpBonusActiveRefundsModal />
      </LazyLoadWrapper>
    </>
  );
};
