import React from "react";
import { RefundHistoryVerificationStatesWrapper } from "../../RefundHistoryVerificationStatesWrapper";
import { Schedule, Sync } from "@material-ui/icons";
import styles from "./RefundHistoryVerificationInProgressState.module.css";

export const RefundHistoryVerificationInProgressState = () => {
  return (
    <RefundHistoryVerificationStatesWrapper
      title="Initiate Customer Refunds Effortlessly"
      description="Your verification request in under processing. We’ll update you as soon it’s done."
      Icon={<Sync className={styles.icon} />}
      btnProps={{
        text: "Verification in progress...",
        disabled: true,
        btnIcon: <Schedule className={styles.btnIcon} />,
      }}
    />
  );
};
